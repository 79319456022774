import { Component, Inject, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { Case } from '@app/_models/case';
import { AccountService, AlertService } from '@app/_services';
import { RestService } from '@app/_services/rest.service';
import { FunctionsService } from '@app/_services/functions.service';
import { GlobalValues } from '@app/_services/global-values';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  refNo: number = 0;
  isMk3User = this.global.userEmailId ?  this.global.userEmailId!.toLowerCase().includes('@mk3.com.au') : false;
  inputDialogRef!: MatDialogRef<InputRefNoDialog>;
  constructor(
    public accountService: AccountService,
    private router: Router,
    public appComponentF: AppComponent,
    public global: GlobalValues,
    public dialog: MatDialog,
    private alertService: AlertService,
    @Optional() public dialogRef: MatDialogRef<InputRefNoDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { refNo: number; isByRefNo: boolean }
  ) {
    if (this.accountService.isUserLoggedIn()) {
      this.router.navigate(['/']);
    }
  }

  public async openSearchDialog(isByRefNo: boolean) {
    this.alertService.clear();
    this.inputDialogRef = this.dialog.open(InputRefNoDialog, {
      minWidth: '400px',
      maxWidth: '1000px',
      data: { isByRefNo: isByRefNo },
    });

    /*this.inputDialogRef.afterClosed().subscribe(result => {
            // console.log('result = ' + result);
            if (result == '' || result == undefined)
                this.alertService.error('Invalid Claim. This claim either could be archived or not yet registered. Please contact Mk3 IT-Helpdesk');
            else
                this.router.navigate(['./case-details/' + result]);
        },
            error => {
                console.log('error = ' + error);
                alert(error);
            }
        )*/
  }
}

@Component({
  selector: 'input-refno-dialog',
  templateUrl: 'input-refno-dialog.html',
})
export class InputRefNoDialog {
  public form!: UntypedFormGroup;
  isLoading = true;
  case!: Case;
  refNo: number = 0;
  caseNo!: string;
  isByRefNo!: boolean;

  constructor(
    public functionsService: FunctionsService,
    private restService: RestService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<InputRefNoDialog>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    this.form = new UntypedFormGroup({
      // refNo: new FormControl('', [Validators.required, Validators.maxLength(5), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      // caseNo: new FormControl('', Validators.required)
      refNo: new UntypedFormControl('', [
        Validators.maxLength(5),
        Validators.pattern(/^-?(0|[1-9]\d*)?$/),
      ]),
      caseNo: new UntypedFormControl(''), //, Validators.maxLength(11))
    });
    this.isByRefNo = this.dialogData.isByRefNo;
    //console.log('data', this.dialogData);
  }

  /*async fetchData(isAddNote: boolean) {
        await this.restService.getClaimByMk3Ref(this.route.snapshot.params['refNo']).then((data: any) => {
          this.case = { ...data };
          this.isLoading = false;
      });
    }*/

  async fetchData(value: string, isByRefNo: boolean): Promise<boolean> {
    //Get case details
    this.isLoading = true;
    let success: boolean = false;
    if (isByRefNo) {
      //await this.restService.isValidCaseID(Number(value));
      /*await this.restService
        .getClaimByMk3Ref(Number(value))
        .then((data: any) => {
          if (data && data.totalCount > 0) {
            this.router.navigate(['./case-details/' + Number(value)]);
          } else {
            this.isLoading = false;
            this.router.navigate(['.']);
            this.alertService.error(
              'Invalid RefNo ' +
                value +
                '. This claim either could be archived or not yet registered. Please contact Mk3 IT-Helpdesk'
            );
          }
        });*/
        this.router.navigate(['./case-details/' + Number(value)]);
    } else {
      await this.restService.getClaimByClaimNumber(value).then((data: any) => {
        if (data && data.totalCount > 0) {
          data = this.functionsService.excludeClaims(data);
          this.router.navigate(['./case-details/' + data.records[0].refNo]);
        } else {
          this.isLoading = false;
          this.router.navigate(['.']);
          this.alertService.error(
            'Invalid Claim Number ' +
              value +
              '. This claim either could be archived or not yet registered. Please contact Mk3 IT-Helpdesk'
          );
        }
      });
    }
    this.dialogRef.close(Number(value));
    return success;
  }

  /*async fetchData(value: string, isByRefNo: boolean) {
    await this.restService
      .getClaimByMk3Ref(this.route.snapshot.params['refNo'])
      .then(async (data) => {
        if (data && data.totalCount > 0) {
          this.vClaimsAll = { ...data.records[0] };
          this.isLoading = false;

          //Get Notes
          await this.restService
            .getClaimNotes(this.route.snapshot.params['refNo'])
            .subscribe(
              (notes) => {
                this.dataSourceNotes = new MatTableDataSource(notes);
                this.dataSourceNotes.sort = this.matSort;
              },
              (error) => {
                this.isLoading = false;
                this.alertService.error(error);
              }
            );

          //Get Claim Contacts
          await this.restService
            .getClaimContacts(this.route.snapshot.params['refNo'])
            .subscribe(
              (contacts) => {
                this.claimContact = contacts;
              },
              (error) => {
                this.isLoading = false;
                this.alertService.error(error);
              }
            );
        } else {
          this.isLoading = false;
          this.router.navigate(['.']);
          this.alertService.error(
            'Invalid Claim. This claim either could be archived or not yet registered. Please contact Mk3 IT-Helpdesk'
          );
          return;
        }
      });
  }*/

  /*async fetchData(value: string, isByRefNo: boolean): Promise<boolean> {
        //Get case details
        this.isLoading = true;
        let success: boolean = false;
        //console.log('inside fetchData: ', this.dialogData);
        //console.log('value: ', value);

        if (isByRefNo) {
            (await this.restService.getClaimByMk3Ref(Number(value)))
                .pipe(first())
                .subscribe(
                    async data => {
                        this.case = { ...data };
                        this.isLoading = false;
                        this.refNo = Number(value);
                        this.caseNo = data.caseNo;
                        this.dialogRef.close(this.case.refNo);
                        success = true;
                        //console.log('refNo: ' + this.case.refNo);
                        //console.log('caseNo: ' + this.case.caseNo);
                        //console.log('this.case in getClaimByMk3Ref: ', this.case);
                    },
                    () => {
                        this.isLoading = false;
                        this.router.navigate(['.']);
                        this.alertService.error('Invalid RefNo ' + value + '. This claim either could be archived or not yet registered. Please contact Mk3 IT-Helpdesk');
                        this.dialogRef.close('');
                    });
        }
        else {
            (await this.restService.getClaimByClaimNumber(value))
                .pipe(first())
                .subscribe(
                    async data => {
                        if (Object.keys(data[0]).length !== 0) {
                            this.case = { ...data[0] };
                            this.isLoading = false;
                            this.refNo = this.case.refNo;
                            this.caseNo = this.case.caseNo;
                            this.dialogRef.close(this.case.refNo);
                            success = true;
                            //console.log('success: ' + success);
                            //console.log('value: ' + value);
                            //console.log('refNo: ' + this.case.refNo);
                            //console.log('caseNo: ' + this.case.caseNo);
                            //console.log('this.case in getClaimByClaimNumber: ', this.case);
                        }
                        else {
                            this.isLoading = false;
                            this.router.navigate(['.']);
                            console.log('ERROR!: ' + value);
                            this.alertService.error('Invalid Claim Number ' + value + '. This claim either could be archived or not yet registered. Please contact Mk3 IT-Helpdesk');
                            this.dialogRef.close('');
                        }
                    },
                    () => {
                        this.isLoading = false;
                        this.router.navigate(['.']);
                        console.log('ERROR!: ' + value);
                        this.alertService.error('Invalid Claim Number ' + value + '. This claim either could be archived or not yet registered. Please contact Mk3 IT-Helpdesk');
                        this.dialogRef.close('');
                    });
        }
        return success;
    }*/

  public async onSubmit() {
    this.dialogData.refNo = this.form.controls['refNo'].value;
    this.dialogData.caseNo = this.form.controls['caseNo'].value;

    //console.log('data on submit: ', this.dialogData);
    //console.log('this.form.controls[refNo].value: ' + this.form.controls['refNo'].value);
    //console.log('this.form.controls[caseNo].value: ' + this.form.controls['caseNo'].value);
    //console.log('this.isByRefNo: ' + this.isByRefNo);
    //console.log('is form valid?: ', !this.form.invalid);
    //console.log('InvalidControls: ' + this.findInvalidControls());

    if (this.form.invalid) {
      return;
    }
    if (this.isByRefNo)
      await this.fetchData(this.form.controls['refNo'].value, this.isByRefNo);
    else {
      let caseNo: string = this.form.controls['caseNo'].value;

      if (caseNo.substring(0, 2) == '1/') caseNo = caseNo.replace('1/', '1');

      if (caseNo.indexOf(' ') > 0)
        caseNo = caseNo.substring(0, caseNo.indexOf(' '));

      this.dialogData.caseNo = caseNo;
      await this.fetchData(caseNo, this.isByRefNo);
    }
  }

  public onCancel = () => {
    this.dialogRef.close('cancel');
    this.alertService.clear();
    return;
  };

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  public findInvalidControls(): string[] {
    const invalid = [];
    const controls = this.form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push('Invalid Control: ' + name);
      }
    }
    return invalid;
  }
}
