import { Injectable } from "@angular/core"; 
import { MsalService } from '@azure/msal-angular';
import { Subject } from "rxjs";

@Injectable({
    providedIn:'root'
})

export class GlobalValues{

//     isDescriptionDDLCloseOnSelect:boolean = true;

//     userInGroupList = this.msalService.instance.getAllAccounts()[0].idTokenClaims;
//     userEmailId = this.msalService.instance.getActiveAccount()!.username! ;
//     userName = this.msalService.instance.getActiveAccount()!.name!;
//     accessToken : any;
    

// constructor(private msalService: MsalService 
//   ){
//   }
isDescriptionDDLCloseOnSelect: boolean = true;
userInGroupList: any;
userEmailId: string | undefined;
userName: string | undefined;
accessToken: any;

constructor(private msalService: MsalService) {
    const accounts = this.msalService.instance.getAllAccounts();
    if (accounts.length > 0) {
        this.userInGroupList = accounts[0].idTokenClaims;
        this.userEmailId = accounts[0].username;
        this.userName = accounts[0].name;
    } else {
        console.warn('No accounts found');
    }
}

}