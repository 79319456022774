<div class="app-container" *ngIf="accountService.isUserLoggedIn() === true"> 
 
    <div class="loading-container" *ngIf="isLoading">
      <div class="lds-grid">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  
    <div class="portal-header">
      <h5 class="header-text">Statistics Report</h5>
    </div>

    <div class="main-table-container">
      <mat-tab-group #tabGroup (selectedTabChange)="onTabClick($event)" [selectedIndex]="selectedTabIndex">
        <mat-tab label="Statistics Report">
          <ng-template mat-tab-label>Statistics Report</ng-template>
          <ng-template matTabContent>
            <h4 style="margin-top:15px;">12 Months Rolling Claim Report - {{getFormattedYear(selectedYear).length===4? 'Year '+ getFormattedYear(selectedYear) : 'Up to '+getFormattedYear(selectedYear)}}</h4>
            <!-- <h6 style="text-align: center;">Report Date: {{today | date:'fullDate'}}</h6> -->
            <h6 style="text-align: center;">Report Date: {{today | date:'fullDate'}}</h6>
            
            
            <div style="display: flex; justify-content: space-between; align-items: flex-end; margin-bottom: 0;">
              <div style="width: 70px; margin-bottom: -15px;">
                <mat-form-field appearance="fill" class="no-label-field" *ngIf="statisticsYears && statisticsYears.length>0">
                  <mat-label class="label-custom">Select a Year or a Month:&nbsp;</mat-label>
                  <mat-select [formControl]="years">
                    <mat-option [value]="getFormattedYear(year)" *ngFor="let year of statisticsYears">
                      {{ getFormattedYear(year) }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div style="display: flex; justify-content: center; align-items: center; margin-bottom: 5px; color: darkblue" *ngIf="selectedYear && getFormattedYear(selectedYear).length>4">
                <i class="material-icons" style="font-size:xx-large;" [ngClass]="{'disabled-icon': isStart}" (click)="!isStart && changeMonth(-2)">first_page</i>
                <i class="material-icons" style="font-size:xxx-large;" [ngClass]="{'disabled-icon': isPrevious}" (click)="!isPrevious && changeMonth(-1)">arrow_left</i>
                <span style="font-weight: bold; font-size: large;">{{ statisticsMonth | date: 'MMMM yyyy' }}</span>
                <i class="material-icons" style="font-size:xxx-large;" [ngClass]="{'disabled-icon': isNext}" (click)="!isNext && changeMonth(1)">arrow_right</i>
                <i class="material-icons" style="font-size:xx-large;" [ngClass]="{'disabled-icon': isEnd}" (click)="!isEnd && changeMonth(2)">last_page</i>
              </div>
              
              <div style="margin-bottom: 5px;">
                <button   
                style="width: fit-content; margin: 8px; float: right;" class="btn btn-sm btn-primary mat-raised-button"
                (click)="exporter.exportTable('xlsx',{fileName:'Yearly Statistics Report ' + functionsService.getCurrentTimestampFormatted()})">Export to Excel
                </button>
              </div>
            </div>
            
            <div>
              <div class="yearly-table">
                <table mat-table matTableExporter class="mat-elevation-z8 custom-table" [dataSource]="dataSourceYearlyStatistics" #exporter="matTableExporter" >
                  <ng-container matColumnDef="sla">
                    <th class="wc-5" *matHeaderCellDef >SLA</th>
                    <td mat-cell *matCellDef="let yearlyStatistics" style="width: 23%;" [style.border-top]="yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? '2px solid gray' : 'none'" [style.background-color]="yearlyStatistics.sla === 'Average quantum of claims under repair by Mk3 Builders/Restorers' || yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? 'lightyellow' : 'white'">{{yearlyStatistics.sla}}</td>
                  </ng-container>
            
                  <ng-container matColumnDef="mk3Target">
                    <th class="wc-4" *matHeaderCellDef  >MK3 Target</th>
                    <td mat-cell wc-8 *matCellDef="let yearlyStatistics" style="width: 8%;" [style.border-top]="yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? '2px solid gray' : 'none'" [style.background-color]="yearlyStatistics.sla === 'Average quantum of claims under repair by Mk3 Builders/Restorers' || yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? 'lightyellow' : 'white'">{{yearlyStatistics.mk3Target}}</td>
                  </ng-container>

                  <ng-container matColumnDef="monthlyAverage">
                    <th class="wc-2" *matHeaderCellDef >Monthly Average</th>
                    <td mat-cell *matCellDef="let yearlyStatistics" style="width: 8%;" [style.border-top]="yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? '2px solid gray' : 'none'" [style.background-color]="yearlyStatistics.sla === 'Average quantum of claims under repair by Mk3 Builders/Restorers' || yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? 'lightyellow' : 'white'">{{ (yearlyStatistics.sla === 'Average quantum of claims under repair by Mk3 Builders/Restorers' || yearlyStatistics.sla === 'Average Quantum of Open Claims' || yearlyStatistics.sla === 'Finalised Average Claim Cost') && yearlyStatistics.monthlyAverage!==null ? ('$' + (yearlyStatistics.monthlyAverage | number : '1.0-0')) : ((yearlyStatistics.sla==='Mk3 Builders/Restorers appointed to proceed with repairs' || yearlyStatistics.sla==='Average quantum of claims under repair by Mk3 Builders/Restorers') && yearlyStatistics.monthlyAverage==null?0:yearlyStatistics.monthlyAverage) }}</td>
                  </ng-container>

                  <ng-container matColumnDef="january">
                    <th class="wc-3"*matHeaderCellDef>January</th>
                    <td mat-cell *matCellDef="let yearlyStatistics" [style.border-top]="yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? '2px solid gray' : 'none'" [style.background-color]="yearlyStatistics.sla === 'Average quantum of claims under repair by Mk3 Builders/Restorers' || yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? 'lightyellow' : 'white'">{{ (yearlyStatistics.sla === 'Average quantum of claims under repair by Mk3 Builders/Restorers' || yearlyStatistics.sla === 'Average Quantum of Open Claims' || yearlyStatistics.sla === 'Finalised Average Claim Cost') && yearlyStatistics.january!==null ? ('$' + (yearlyStatistics.january | number : '1.0-0')) : ((yearlyStatistics.sla==='Mk3 Builders/Restorers appointed to proceed with repairs' || yearlyStatistics.sla==='Average quantum of claims under repair by Mk3 Builders/Restorers') && yearlyStatistics.january==null?0:yearlyStatistics.january) }}</td>
                  </ng-container>

                  <ng-container matColumnDef="february">
                    <th class="wc-3" *matHeaderCellDef>February</th>
                    <td mat-cell *matCellDef="let yearlyStatistics" [style.border-top]="yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? '2px solid gray' : 'none'" [style.background-color]="yearlyStatistics.sla === 'Average quantum of claims under repair by Mk3 Builders/Restorers' || yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? 'lightyellow' : 'white'">{{ (yearlyStatistics.sla === 'Average quantum of claims under repair by Mk3 Builders/Restorers' || yearlyStatistics.sla === 'Average Quantum of Open Claims' || yearlyStatistics.sla === 'Finalised Average Claim Cost') && yearlyStatistics.february!==null ? ('$' + (yearlyStatistics.february | number : '1.0-0')): ((yearlyStatistics.sla==='Mk3 Builders/Restorers appointed to proceed with repairs' || yearlyStatistics.sla==='Average quantum of claims under repair by Mk3 Builders/Restorers') && yearlyStatistics.february==null?0:yearlyStatistics.february )}}</td>
                  </ng-container>

                  <ng-container matColumnDef="march">
                    <th class="wc-3" *matHeaderCellDef >March</th>
                    <td mat-cell *matCellDef="let yearlyStatistics" [style.border-top]="yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? '2px solid gray' : 'none'" [style.background-color]="yearlyStatistics.sla === 'Average quantum of claims under repair by Mk3 Builders/Restorers' || yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? 'lightyellow' : 'white'">{{ (yearlyStatistics.sla === 'Average quantum of claims under repair by Mk3 Builders/Restorers' || yearlyStatistics.sla === 'Average Quantum of Open Claims' || yearlyStatistics.sla === 'Finalised Average Claim Cost') && yearlyStatistics.march!==null ? ('$' + (yearlyStatistics.march | number : '1.0-0')) : ((yearlyStatistics.sla==='Mk3 Builders/Restorers appointed to proceed with repairs' || yearlyStatistics.sla==='Average quantum of claims under repair by Mk3 Builders/Restorers') && yearlyStatistics.march==null?0:yearlyStatistics.march )}}</td>
                  </ng-container>

                  <ng-container matColumnDef="april">
                    <th class="wc-3" *matHeaderCellDef >April</th>
                    <td mat-cell *matCellDef="let yearlyStatistics" [style.border-top]="yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? '2px solid gray' : 'none'" [style.background-color]="yearlyStatistics.sla === 'Average quantum of claims under repair by Mk3 Builders/Restorers' || yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? 'lightyellow' : 'white'">{{ (yearlyStatistics.sla === 'Average quantum of claims under repair by Mk3 Builders/Restorers' || yearlyStatistics.sla === 'Average Quantum of Open Claims' || yearlyStatistics.sla === 'Finalised Average Claim Cost') && yearlyStatistics.april!==null ? ('$' + (yearlyStatistics.april | number : '1.0-0')) : ((yearlyStatistics.sla==='Mk3 Builders/Restorers appointed to proceed with repairs' || yearlyStatistics.sla==='Average quantum of claims under repair by Mk3 Builders/Restorers') && yearlyStatistics.april==null?0:yearlyStatistics.april ) }}</td>
                  </ng-container>

                  <ng-container matColumnDef="may">
                    <th class="wc-3" *matHeaderCellDef >May</th>
                    <td mat-cell *matCellDef="let yearlyStatistics" [style.border-top]="yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? '2px solid gray' : 'none'" [style.background-color]="yearlyStatistics.sla === 'Average quantum of claims under repair by Mk3 Builders/Restorers' || yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? 'lightyellow' : 'white'">{{ (yearlyStatistics.sla === 'Average quantum of claims under repair by Mk3 Builders/Restorers' || yearlyStatistics.sla === 'Average Quantum of Open Claims' || yearlyStatistics.sla === 'Finalised Average Claim Cost') && yearlyStatistics.may!==null ? ('$' + (yearlyStatistics.may | number : '1.0-0')) : ((yearlyStatistics.sla==='Mk3 Builders/Restorers appointed to proceed with repairs' || yearlyStatistics.sla==='Average quantum of claims under repair by Mk3 Builders/Restorers') && yearlyStatistics.may==null?0:yearlyStatistics.may )}}</td>
                  </ng-container>

                  <ng-container matColumnDef="june">
                    <th class="wc-23" *matHeaderCellDef >June</th>
                    <td mat-cell *matCellDef="let yearlyStatistics" [style.border-top]="yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? '2px solid gray' : 'none'" [style.background-color]="yearlyStatistics.sla === 'Average quantum of claims under repair by Mk3 Builders/Restorers' || yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? 'lightyellow' : 'white'">{{ (yearlyStatistics.sla === 'Average quantum of claims under repair by Mk3 Builders/Restorers' || yearlyStatistics.sla === 'Average Quantum of Open Claims' || yearlyStatistics.sla === 'Finalised Average Claim Cost') && yearlyStatistics.june!==null ? ('$' + (yearlyStatistics.june | number : '1.0-0')) : ((yearlyStatistics.sla==='Mk3 Builders/Restorers appointed to proceed with repairs' || yearlyStatistics.sla==='Average quantum of claims under repair by Mk3 Builders/Restorers') && yearlyStatistics.june==null?0:yearlyStatistics.june )}}</td>
                  </ng-container>

                  <ng-container matColumnDef="july">
                    <th class="wc-3" *matHeaderCellDef >July</th>
                    <td mat-cell *matCellDef="let yearlyStatistics" [style.border-top]="yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? '2px solid gray' : 'none'" [style.background-color]="yearlyStatistics.sla === 'Average quantum of claims under repair by Mk3 Builders/Restorers' || yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? 'lightyellow' : 'white'">{{ (yearlyStatistics.sla === 'Average quantum of claims under repair by Mk3 Builders/Restorers' || yearlyStatistics.sla === 'Average Quantum of Open Claims' || yearlyStatistics.sla === 'Finalised Average Claim Cost') && yearlyStatistics.july!==null ? ('$' + (yearlyStatistics.july | number : '1.0-0')) : ((yearlyStatistics.sla==='Mk3 Builders/Restorers appointed to proceed with repairs' || yearlyStatistics.sla==='Average quantum of claims under repair by Mk3 Builders/Restorers') && yearlyStatistics.july==null?0:yearlyStatistics.july) }}</td>
                  </ng-container>

                  <ng-container matColumnDef="august">
                    <th class="wc-3" *matHeaderCellDef >August</th>
                    <td mat-cell *matCellDef="let yearlyStatistics" [style.border-top]="yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? '2px solid gray' : 'none'" [style.background-color]="yearlyStatistics.sla === 'Average quantum of claims under repair by Mk3 Builders/Restorers' || yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? 'lightyellow' : 'white'">{{ (yearlyStatistics.sla === 'Average quantum of claims under repair by Mk3 Builders/Restorers' || yearlyStatistics.sla === 'Average Quantum of Open Claims' || yearlyStatistics.sla === 'Finalised Average Claim Cost') && yearlyStatistics.august!==null ? ('$' + (yearlyStatistics.august | number : '1.0-0')) : ((yearlyStatistics.sla==='Mk3 Builders/Restorers appointed to proceed with repairs' || yearlyStatistics.sla==='Average quantum of claims under repair by Mk3 Builders/Restorers') && yearlyStatistics.august==null?0:yearlyStatistics.august) }}</td>
                  </ng-container>

                  <ng-container matColumnDef="september">
                    <th class="wc-3" *matHeaderCellDef >September</th>
                    <td mat-cell *matCellDef="let yearlyStatistics" [style.border-top]="yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? '2px solid gray' : 'none'" [style.background-color]="yearlyStatistics.sla === 'Average quantum of claims under repair by Mk3 Builders/Restorers' || yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? 'lightyellow' : 'white'">{{ (yearlyStatistics.sla === 'Average quantum of claims under repair by Mk3 Builders/Restorers' || yearlyStatistics.sla === 'Average Quantum of Open Claims' || yearlyStatistics.sla === 'Finalised Average Claim Cost') && yearlyStatistics.september!==null ? ('$' + (yearlyStatistics.september | number : '1.0-0')) :((yearlyStatistics.sla==='Mk3 Builders/Restorers appointed to proceed with repairs' || yearlyStatistics.sla==='Average quantum of claims under repair by Mk3 Builders/Restorers') && yearlyStatistics.september==null?0:yearlyStatistics.september) }}</td>
                  </ng-container>

                  <ng-container matColumnDef="october">
                    <th class="wc-3" *matHeaderCellDef >October</th>
                    <td mat-cell *matCellDef="let yearlyStatistics" [style.border-top]="yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? '2px solid gray' : 'none'" [style.background-color]="yearlyStatistics.sla === 'Average quantum of claims under repair by Mk3 Builders/Restorers' || yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? 'lightyellow' : 'white'">{{ (yearlyStatistics.sla === 'Average quantum of claims under repair by Mk3 Builders/Restorers' || yearlyStatistics.sla === 'Average Quantum of Open Claims' || yearlyStatistics.sla === 'Finalised Average Claim Cost') && yearlyStatistics.october!==null ? ('$' + (yearlyStatistics.october | number : '1.0-0')) : ((yearlyStatistics.sla==='Mk3 Builders/Restorers appointed to proceed with repairs' || yearlyStatistics.sla==='Average quantum of claims under repair by Mk3 Builders/Restorers') && yearlyStatistics.october==null?0:yearlyStatistics.october) }}</td>
                  </ng-container>

                  <ng-container matColumnDef="november">
                    <th class="wc-3" *matHeaderCellDef >November</th>
                    <td mat-cell *matCellDef="let yearlyStatistics" [style.border-top]="yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? '2px solid gray' : 'none'" [style.background-color]="yearlyStatistics.sla === 'Average quantum of claims under repair by Mk3 Builders/Restorers' || yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? 'lightyellow' : 'white'">{{ (yearlyStatistics.sla === 'Average quantum of claims under repair by Mk3 Builders/Restorers' || yearlyStatistics.sla === 'Average Quantum of Open Claims' || yearlyStatistics.sla === 'Finalised Average Claim Cost') && yearlyStatistics.november!==null ? ('$' + (yearlyStatistics.november | number : '1.0-0')) : ((yearlyStatistics.sla==='Mk3 Builders/Restorers appointed to proceed with repairs' || yearlyStatistics.sla==='Average quantum of claims under repair by Mk3 Builders/Restorers') && yearlyStatistics.november==null?0:yearlyStatistics.november)}}</td>
                  </ng-container>

                  <ng-container matColumnDef="december">
                    <th class="wc-3" *matHeaderCellDef >December</th>
                    <td mat-cell *matCellDef="let yearlyStatistics" [style.border-top]="yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? '2px solid gray' : 'none'" [style.background-color]="yearlyStatistics.sla === 'Average quantum of claims under repair by Mk3 Builders/Restorers' || yearlyStatistics.sla === 'Mk3 Builders/Restorers appointed to proceed with repairs' ? 'lightyellow' : 'white'">{{ (yearlyStatistics.sla === 'Average quantum of claims under repair by Mk3 Builders/Restorers' || yearlyStatistics.sla === 'Average Quantum of Open Claims' || yearlyStatistics.sla === 'Finalised Average Claim Cost') && yearlyStatistics.december!==null ? ('$' + (yearlyStatistics.december | number : '1.0-0')) : ((yearlyStatistics.sla==='Mk3 Builders/Restorers appointed to proceed with repairs' || yearlyStatistics.sla==='Average quantum of claims under repair by Mk3 Builders/Restorers') && yearlyStatistics.december==null?0:yearlyStatistics.december) }}</td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsYearly"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsYearly;" class="row-hover mat-row"></tr>
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="9999">No statistics data available for this year!</td>
                  </tr>
                </table>
              </div>
              <label style="margin: 9px;">Top 3 Loss Types: </label><span style="font-weight: bold;font-size: larger;">{{ top3Loss ? top3Loss : 'Not Available' }}</span>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label="Claims Detail">
          <ng-template mat-tab-label>Claims Details</ng-template>
          <ng-template matTabContent>
            <h4 style="margin-top:15px;">Claims Details - {{selectedMonthsYears | date: 'MMMM yyyy'}}</h4>
            <h6 style="text-align: center;">Report Date: {{today | date:'fullDate'}}</h6>

            <div style="display: flex; justify-content: space-between; align-items: flex-end; margin-bottom: 0;">
              <div style="width: 70px; margin-bottom: -15px;">
                <mat-form-field appearance="fill" class="no-label-field">
                  <mat-label class="label-custom">Select a Month:&nbsp;</mat-label>
                  <mat-select [formControl]="months">
                    <mat-option [value]="date | date: 'MMMM yyyy'" *ngFor="let date of monthsYears">
                      {{ date | date: 'MMMM yyyy' }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div style="margin-bottom: 5px;">
                <button style="width: fit-content;" class="btn btn-sm btn-primary mat-raised-button"
                  (click)="exporter.exportTable('xlsx',{fileName:'Claims Details Report ' + functionsService.getCurrentTimestampFormatted()})">
                  Export to Excel
                </button>
              </div>
            </div>

            <div>
                <table mat-table matTableExporter class="mat-elevation-z8 custom-table" [dataSource]="dataSourceClaimsReport" #exporter="matTableExporter" matSort matSortStart="desc" matSortDisableClear (matSortChange)="sortData($event)">
                  <ng-container matColumnDef="claimNumber">
                    <th class="wc-2" mat-header-cell *matHeaderCellDef mat-sort-header>Claim Number</th>
                    <td mat-cell *matCellDef="let claimsReport">{{claimsReport.claimNumber}}</td>
                  </ng-container>
            
                  <ng-container matColumnDef="otherReferenceNumber">
                    <th class="wc-1" mat-header-cell *matHeaderCellDef mat-sort-header>Other Reference Number</th>
                    <td mat-cell *matCellDef="let claimsReport">{{claimsReport.otherReferenceNumber}}</td>
                  </ng-container>

                  <ng-container matColumnDef="propertyAddress" class="property-address-column">
                    <th class="wc-20" mat-header-cell *matHeaderCellDef mat-sort-header style="width: 28%;">Property Address</th>
                    <td mat-cell *matCellDef="let claimsReport" style="width: 28%;">{{claimsReport.propertyAddress}}</td>
                  </ng-container>
            
                  <ng-container matColumnDef="state">
                    <th class="wc-1" mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
                    <td mat-cell *matCellDef="let claimsReport">{{claimsReport.state}}</td>
                  </ng-container>

                  <ng-container matColumnDef="postCode">
                    <th class="wc-1" mat-header-cell *matHeaderCellDef mat-sort-header>Postcode</th>
                    <td mat-cell *matCellDef="let claimsReport">{{claimsReport.postCode}}</td>
                  </ng-container>
            
                  <ng-container matColumnDef="claimType">
                    <th class="wc-4" mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;">Claim Type</th>
                    <td mat-cell *matCellDef="let claimsReport" style="width: 10%;">{{claimsReport.claimType}}</td>
                  </ng-container>

                  <ng-container matColumnDef="quantumOfClaim">
                    <th class="wc-2" mat-header-cell *matHeaderCellDef mat-sort-header>Quantum Of Claim</th>
                    <td mat-cell *matCellDef="let claimsReport">{{ ('$' + (claimsReport.quantumOfClaim | number : '1.2-2')) }}</td>
                  </ng-container>
            
                  <ng-container matColumnDef="dateOfAppointment">
                    <th class="wc-2" mat-header-cell *matHeaderCellDef mat-sort-header>Date Of Appointment</th>
                    <td mat-cell *matCellDef="let claimsReport">{{claimsReport.dateOfAppointment | date: 'yyyy-MM-dd'}}</td>
                  </ng-container>

                  <ng-container matColumnDef="dateOfInitialCustomerContact">
                    <th class="wc-2" mat-header-cell *matHeaderCellDef mat-sort-header>Date Of Initial Customer Contact</th>
                    <td mat-cell *matCellDef="let claimsReport">{{claimsReport.dateOfInitialCustomerContact | date: 'yyyy-MM-dd'}}</td>
                  </ng-container>
            
                  <ng-container matColumnDef="dateOfSiteVisit">
                    <th class="wc-2" mat-header-cell *matHeaderCellDef mat-sort-header>Date Of Site Visit</th>
                    <td mat-cell *matCellDef="let claimsReport">{{claimsReport.dateOfSiteVisit | date: 'yyyy-MM-dd'}}</td>
                  </ng-container>

                  <ng-container matColumnDef="dateOfFirstReport">
                    <th class="wc-5" mat-header-cell *matHeaderCellDef mat-sort-header>Date Of First Report</th>
                    <td mat-cell *matCellDef="let claimsReport">{{claimsReport.dateOfFirstReport | date: 'yyyy-MM-dd'}}</td>
                  </ng-container>
                                                    
                  <ng-container matColumnDef="dateClosed">
                    <th class="wc-3" mat-header-cell *matHeaderCellDef mat-sort-header>Date Closed</th>
                    <td mat-cell *matCellDef="let claimsReport">{{claimsReport.dateClosed | date: 'yyyy-MM-dd'}}</td>
                  </ng-container>

                  <ng-container matColumnDef="claimFinalisedDate">
                    <th class="wc-3" mat-header-cell *matHeaderCellDef mat-sort-header>Claim Finalised Date</th>
                    <td mat-cell *matCellDef="let claimsReport">{{claimsReport.claimFinalisedDate | date: 'yyyy-MM-dd'}}</td>
                  </ng-container>

                  <ng-container matColumnDef="statusWithPanels">
                    <th class="wc-5" mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;">Status With Panels</th>
                    <td mat-cell *matCellDef="let claimsReport" style="width: 10%;">{{claimsReport.statusWithPanels}}</td>
                  </ng-container>
                                                    
                  <ng-container matColumnDef="anticipatedCompletionDate">
                    <th class="wc-3" mat-header-cell *matHeaderCellDef mat-sort-header>Anticipated Completion Date</th>
                    <td mat-cell *matCellDef="let claimsReport">{{claimsReport.anticipatedCompletionDate | date: 'yyyy-MM-dd'}}</td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsReport"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsReport;" class="row-hover mat-row"></tr>
                </table>

                <mat-paginator *ngIf="dataSourceClaimsReport" #paginator [pageSize]="pageSizeControl.value" [pageSizeOptions]="[10, 20, 50, 100, dataSourceClaimsReport.data.length]" showFirstLastButtons class="mat-paginator" (page)="onPageSizeChanged()" >
                </mat-paginator>

            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>

</div>
</div>