import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/_environments/environment';
import { lastValueFrom, Observable, throwError, of, EMPTY } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Case } from '@app/_models/case';
import { Cat } from '@app/_models/cat';
import { ClaimNote } from '../_models/noteDetails';
import { AccountService, AlertService } from '.';
import { ReceivedSummary } from '@app/_models/received-summary';
import { DatePipe } from '@angular/common';
import { Peril } from '@app/_models/peril';
import { Note } from '@app/_models/note';
import { Router } from '@angular/router';
import { VClaimsAll } from '@app/_models/vClaimsAll';
import { EOM } from '@app/_models/eOM';
import { VClaim } from '@app/_models/vClaim';
import { pagedVClaim } from '@app/_models/paged-vclaim';
import { Statistics } from '@app/_models/statistics';
import { VClaimReport } from '@app/_models/vClaimReport';
import { YearlyStatistics } from '@app/_models/yearlyStatistics';
import { FunctionsService } from '@app/_services/functions.service';

@Injectable({
  providedIn: 'root',
})
export class RestService {
  errorMsg: string = '';
  //private cache$!: Observable<Case[]>;
  //Inject the HttpClient module to the constructor params
  constructor(
    private http: HttpClient,
    private accountService: AccountService,
    private alertService: AlertService,
    private datePipe: DatePipe,
    private router: Router,
    private exclude: FunctionsService
  ) {}

  // getData() {
  //   if (!this.cache$) {
  //     this.cache$ = this.anyHttpCall().pipe(
  //       shareReplay(1)
  //     );
  //   }
  //   return this.cache$;
  // }

  // anyHttpCall(): Observable<Case[]> {
  //   return this.http.get<Object[]>(`${environment.casesLatestApiUrl}` + this.accountService.subscriptionKey).pipe(
  //     // use the map() operator to return the data property of the response object
  //     // the operator enables to map the response of the Observable stream to the data value
  //     map(data => data.map(obj => this.toCase(obj))),
  //     catchError((err) => {
  //       this.alertService.clear();
  //       this.alertService.error(err);
  //       //Handle the errors
  //       return throwError(err);//Rethrow it back to component
  //     })
  //   );
  // }

  //GET the list of latest cases from the REST API using HttpClient
  getLatestCases(): Observable<Case[]> {
    return this.http
      .get<Object[]>(
        `${environment.claimsLatestApiUrl}` +
          this.accountService.isProplabSubscriptionKey
      )
      .pipe(
        // use the map() operator to return the data property of the response object
        // the operator enables to map the response of the Observable stream to the data value
        map((data) => data.map((obj) => this.toCase(obj))),
        catchError((err) => {
          this.alertService.clear();
          this.alertService.error(err);
          //Handle the errors
          return throwError(err); //Rethrow it back to component
        })
      );
  }

  async getLatestCasesAsync() {
    const promise = new Promise<Case[]>((resolve, reject) => {
      this.http
        .get<Object[]>(
          `${environment.claimsLatestApiUrl}` +
            this.accountService.isProplabSubscriptionKey
        )
        .subscribe({
          next: (res: any) => {
            return resolve(res.map((res: any) => this.toCase(res)));
          },
          error: (error: any) => {
            reject(error);
          },
          complete: () => {},
        });
    });
    return promise;
  }

  //GET the list of latest cases from the REST API using HttpClient
  /*getVClaim(consultant: string, adminUser: boolean): Observable<VClaim[]> {
    var url =
      `${environment.wgilAPIBaseUrl}` +
      'vWGICases/Claims' +
      this.accountService.subscriptionKey +
      '&Status=Open';
    var claims = this.http.get<Object[]>(url).pipe(
      map((data) => data.map((obj) => this.toVClaim(obj))),
      catchError((err) => {
        this.alertService.clear();
        this.alertService.error(err);
        //Handle the errors
        return throwError(err); //Rethrow it back to component
      })
    );
    return claims;
  }*/

  /*getVClaimsAll(
    consultant: string,
    adminUser: boolean
  ): Observable<VClaimsAll[]> {
    var url =
      `${environment.wgilAPIBaseUrl}` +
      'vClaimsAll/Claims' +
      this.accountService.subscriptionKey +
      '&Status=Open';
    var claims = this.http.get<Object[]>(url).pipe(
      map((data) => data.map((obj) => this.toVClaimsAll(obj))),
      catchError((err) => {
        this.alertService.clear();
        this.alertService.error(err);
        //Handle the errors
        return throwError(err); //Rethrow it back to component
      })
    );
    return claims;
  }*/

  getTop3Loss(clientId: number, date: Date, year:number): Observable<string> {
    const formattedDate = date.toISOString();
    return this.http.get<string>(
      `${environment.wgilAPIBaseUrl}` +
        'Statistics/GetTop3Loss' +
        this.accountService.subscriptionKey +
        '&date=' +
        `${formattedDate}` +
        '&clientId=' +
        `${clientId}` +
        '&year=' +
        `${year}`,
      { responseType: 'text' as 'json' }
    );
  }

  async getStatisticsByClientID(clientId: number): Promise<Observable<pagedVClaim>>{
    const result = await this.http
    .get<pagedVClaim>(
      `${environment.wgilAPIBaseUrl}` +
        'Statistics/GetStatisticsByClientID' +
        this.accountService.subscriptionKey +
        '&clientId=' +
        `${clientId}`
    )
    .toPromise();
    return result? of(result) : EMPTY;
  }

  async get12MonthsStatisticsByMonthYear(clientId: number, year: number, month:number) {
    const result = this.http
      .get<any[]>(
        `${environment.wgilAPIBaseUrl}` +
          'Statistics/get12MonthsStatisticsByMonthYear' +
          this.accountService.subscriptionKey +
          '&year=' + year +
          '&month=' + month +
          '&clientId=' +
          `${clientId}`
      )
      .toPromise();
    return result;
  }

  async getYearlyStatistics(clientId: number, dates: Date) {
    const formattedDate = dates.toISOString();
    const result = this.http
      .get<pagedVClaim>(
        `${environment.wgilAPIBaseUrl}` +
          'Statistics/GetYearlyStatistics' +
          this.accountService.subscriptionKey +
          '&date=' +
          `${formattedDate}` +
          '&clientId=' +
          `${clientId}`
      )
      .toPromise();
    return result;
  }

  async getStatisticsByYear(clientId: number, year: number){//: Promise<Observable<pagedVClaim>> {
    const result = await this.http
      .get<pagedVClaim>(
        `${environment.wgilAPIBaseUrl}` +
          'Statistics/GetStatisticsByYear' +
          this.accountService.subscriptionKey +
          '&year=' +
          `${year}` +
          '&clientId=' +
          `${clientId}`
      )
      .pipe(
        catchError(error => {
          if (error.status === 404) {
            return of({ totalCount: 0, records: []  } as pagedVClaim);
          }
          return EMPTY;
        })
      )
      .toPromise();
    return result ? of(result) : EMPTY;
  }

  async getVClaimReport(clientId: number) {
    const result = this.http
      .get<any[]>(
        `${environment.wgilAPIBaseUrl}` +
          'Statistics/GetClaimReport' +
          this.accountService.subscriptionKey +
          '&clientId=' +
          `${clientId}`
      )
      .toPromise();
    return result;
  }

  async getEOMClaimReport(clientId: number) : Promise<Observable<any[]>> {
    const res = await this.http
    .get<any[]>(
      `${environment.wgilAPIBaseUrl}` +
        'Statistics/GetEOMClaimReport' +
        this.accountService.subscriptionKey +
        '&clientId=' +
        `${clientId}`
    ).pipe(map((data) => data.map((obj) => this.toEom(obj)))).toPromise();
    return res? of(res) : of([]);
  }

  async getEOMClaimReportByDate(clientId: number, dates: Date) {
    const formattedDate = dates.toISOString();
    const result = this.http
      .get<any[]>(
        `${environment.wgilAPIBaseUrl}` +
          'Statistics/GetEOMClaimReportByCreateDate' +
          this.accountService.subscriptionKey +
          '&date=' +
          `${formattedDate}` +
          '&clientId=' +
          `${clientId}`
      )
      .toPromise();
    return result;
  }

  async getPagedStatistics(
    pageNumber: number,
    pageSize: number,
    clientId: number,
    searchTerm: string = '',
    sortTerm: string = ''
  ) {
    const result = this.http
      .get<pagedVClaim>(
        `${environment.wgilAPIBaseUrl}` +
          'Statistics/GetStatisticsByClientID' +
          this.accountService.subscriptionKey +
          '&pageNumber=' +
          `${pageNumber}` +
          '&pageSize=' +
          `${pageSize}` +
          '&searchTerm=' +
          `${searchTerm}` +
          '&clientId=' +
          `${clientId}`
      )
      .toPromise();
    return result;
  }

  async getPagedVClaims(
    pageNumber: number,
    pageSize: number,
    searchTerm: string = '',
    clientFriendlyName: string = '',
    isHMAUser: Boolean = false,
    isLatest: Boolean = true
  ) {
    const result = this.http
      .get<pagedVClaim>(
        `${environment.wgilAPIBaseUrl}` +
          'vClaimsAll/PagedClaimRecords' +
          this.accountService.subscriptionKey +
          '&pageNumber=' +
          `${pageNumber}` +
          '&pageSize=' +
          `${pageSize}` +
          '&searchTerm=' +
          `${searchTerm}` +
          '&isHMAUser=' +
          `${isHMAUser}` +
          `&clientFriendlyName=` +
          `${clientFriendlyName}` +
          '&isLatest=' +
          `${isLatest}`
      )
      .toPromise();
    return result;
    //return await this.http.get<pagedVClaim>(`${environment.wgilAPIBaseUrl}` + '/vClaimsAll/PagedClaimRecords' + this.accountService.subscriptionKey + '&clientFriendlyName=JvRcaTkbYXlirKZj1wgRaQ%3D%3D').toPromise();
  }

  // Get Paged List of Claims
  getvClaimsPaged(
    pageNumber: number,
    pageSize: number,
    searchTerm: string = '',
    clientFriendlyName: string = '',
    isHMAUser: Boolean = false,
    isLatest: Boolean = true
  ): Observable<VClaimsAll> {
    const result = this.http.get<VClaimsAll>(
      `${environment.wgilAPIBaseUrl}` +
        'vClaimsAll/PagedClaimRecords?pageNumber=' +
        `${pageNumber}` +
        '&pageSize=' +
        `${pageSize}` +
        '&searchTerm=' +
        `${searchTerm}` +
        '&isHMAUser=' +
        `${isHMAUser}` +
        `&clientFriendlyName=` +
        `${clientFriendlyName}` +
        '&isLatest=' +
        `${isLatest}` +
        '&subscription-key=79c1934d47f54b818f72e00b76118d6a'
    );
    return result;
  }

  //GET the list of latest cases from the REST API using HttpClient
  getAllOpenCases(): Observable<Case[]> {
    return this.http
      .get<Object[]>(
        `${environment.wgilAPIBaseUrl}` +
          'WgicasesOpens' +
          this.accountService.subscriptionKey
      )
      .pipe(
        // use the map() operator to return the data property of the response object
        // the operator enables to map the response of the Observable stream to the data value
        map((data) => data.map((obj) => this.toCase(obj))),
        catchError((err) => {
          this.alertService.clear();
          this.alertService.error(err);
          //Handle the errors
          return throwError(err); //Rethrow it back to component
        })
      );
  }

  //GET the list of latest cases from the REST API using HttpClient
  getCATClaims(): Observable<Case[]> {
    return this.http
      .get<Object[]>(
        `${environment.catReportApiUrl}` + this.accountService.subscriptionKey
      )
      .pipe(
        // use the map() operator to return the data property of the response object
        // the operator enables to map the response of the Observable stream to the data value
        map((data) => data.map((obj) => this.toCase(obj))),
        catchError((err) => {
          this.alertService.clear();
          this.alertService.error(err);
          //Handle the errors
          return throwError(err); //Rethrow it back to component
        })
      );
  }

  //GET the list of latest cases from the REST API using HttpClient
  getE2eEoLClaims(): Observable<Case[]> {
    return this.http
      .get<Object[]>(
        `${environment.e2eReportApiUrl}` + this.accountService.subscriptionKey
      )
      .pipe(
        // use the map() operator to return the data property of the response object
        // the operator enables to map the response of the Observable stream to the data value
        map((data) => data.map((obj) => this.toCase(obj))),
        catchError((err) => {
          this.alertService.clear();
          this.alertService.error(err);
          //Handle the errors
          return throwError(err); //Rethrow it back to component
        })
      );
  }

  //GET the list of latest cases from the REST API using HttpClient
  getCatsList(): Observable<Cat[]> {
    const params = new HttpParams().set('sort', 'catId').set('order', 'desc');

    return this.http
      .get<Object[]>(
        `${environment.catsListApiUrl}` + this.accountService.subscriptionKey,
        { params: params }
      )
      .pipe(
        // use the map() operator to return the data property of the response object
        // the operator enables to map the response of the Observable stream to the data value
        map((data) => data.map((obj) => this.toCAT(obj)).sort()),
        catchError((err) => {
          this.alertService.clear();
          this.alertService.error(err);
          //Handle the errors
          return throwError(err); //Rethrow it back to component
        })
      );
  }

  async isValidCaseID(refNo: number): Promise<boolean> {
    let retVal: boolean = false;
    const promise = this.http
      .get(
        `${environment.claimsLatestApiUrl}` +
          '/IsValidRefNo/' +
          refNo +
          this.accountService.isProplabSubscriptionKey
      )
      .toPromise();
    promise
      .then((data) => {
        retVal = JSON.stringify(data) == 'true' ? true : false;
        if (retVal) this.router.navigate(['./case-details/' + refNo]);
        else {
          this.router.navigate(['.']);
          this.alertService.error(
            'Invalid RefNo ' +
              refNo +
              '. This claim either could be archived or not yet registered. Please contact Mk3 IT-Helpdesk'
          );
        }
      })
      .catch(() => {
        retVal = false;
        this.router.navigate(['.']);
        this.alertService.error(
          'Unspecified error. Please contact Mk3 IT-Helpdesk'
        );
      });
    return retVal;
  }

  //GET claim details by ID (Mk3refNo)
  async getClaimByMk3Ref(id: number) {
    var url =
      `${environment.wgilAPIBaseUrl}` +
      'vClaimsAll/PagedClaimRecords' +
      this.accountService.subscriptionKey +
      '&RefNo=' +
      id +
      //'&Status=Open&PageSize=1&clientFriendlyName=' + this.accountService.clientFriendlyName;
      '&PageSize=1&clientFriendlyName=' +
      this.accountService.clientFriendlyName;
    return this.http.get<pagedVClaim>(url).toPromise();
  }

  async getClaimByClaimNumber(caseNo: string) {
    var url =
      `${environment.wgilAPIBaseUrl}` +
      'vClaimsAll/PagedClaimRecords' +
      this.accountService.subscriptionKey +
      '&CaseNo=' +
      caseNo +
      //'&Status=Open&PageSize=1&clientFriendlyName=' + this.accountService.clientFriendlyName;
      '&PageSize=1&clientFriendlyName=' +
      this.accountService.clientFriendlyName;
    return this.http.get<pagedVClaim>(url).toPromise();
  }

  //GET Claim Notes by ID (refNo)
  getClaimNotes(refNo: number): Observable<ClaimNote[]> {
    return this.http
      .get<Object[]>(
        `${environment.claimNotesListApiUrl}` +
          '/' +
          refNo +
          this.accountService.subscriptionKey+
          
          '&IsHmaOrContractor=' +
          false 
      )
      .pipe(
        //map(data => data.map(obj => this.toNoteDetails(obj))),
        map((data) =>
          data
            .map((obj) => this.toNoteDetails(obj))
            .filter((d) => d.private == 0)
        ),
        catchError((error) => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.clear();
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  //GET Claim Received Summaries For the Date Range
  getClaimReceivedSummary(startDate: Date, endDate: Date): Observable<any> {
    return this.http
      .get<ReceivedSummary[]>(
        `${environment.wgilAPIBaseUrl}` +
          'ClaimReceivedSummeries' +
          this.accountService.subscriptionKey +
          '&fromDT=' +
          this.datePipe.transform(startDate, 'yyyy-MM-dd') +
          '&toDT=' +
          this.datePipe.transform(endDate, 'yyyy-MM-dd')
      )
      .pipe(
        //map(data => data.map(obj => this.toSummary(obj))),
        catchError((error) => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.clear();
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  //GET Perils Summary For the Date Range
  getPerilsSummary(startDate?: Date, endDate?: Date): Observable<any> {
    return this.http
      .get<ReceivedSummary[]>(
        `${environment.wgilAPIBaseUrl}` +
          'PerilsSummaries' +
          this.accountService.subscriptionKey +
          '&fromDT=' +
          this.datePipe.transform(startDate, 'yyyy-MM-dd') +
          '&toDT=' +
          this.datePipe.transform(endDate, 'yyyy-MM-dd')
      )
      .pipe(
        map((data) => data.map((obj) => this.toPeril(obj))),
        catchError((error) => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.clear();
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  addNewNote(note: Note) {
    return this.http
      .post(
        `${environment.wgilAPIBaseUrl}` +
          'WgilClaimNotes' +
          this.accountService.subscriptionKey,
        note
      )
      .pipe(
        catchError((error) => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.clear();
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  getClaimFiles(filePath: string): Observable<any> {
    return this.http
      .get<string>(
        `${environment.mk3AzureApiUrl}` +
          '/Files/' +
          filePath +
          this.accountService.subscriptionKey
      )
      .pipe(catchError(this.handleError));
  }

  downloadTest() {
    return this.http.get(`${environment.mk3AzureApiUrl}` + '/Download/Export', {
      observe: 'response',
      responseType: 'blob',
    });
  }

  downloadFile(folderName: string, fileName: string) {
    //const promise = new Promise<Blob>((resolve, reject) => {
    //const headers = new HttpHeaders({ 'Content-Type': 'application/json', responseType : 'arraybuffer'});//arraybuffer
    //const headers = new HttpHeaders({ observe: 'response', responseType : 'blob'});//arraybuffer

    //headers.append("x-ms-date", Date.now.toString("R", CultureInfo.InvariantCulture));
    //headers.append("x-ms-date", moment(new Date()).toString());
    //headers.append("x-ms-version", "2021-08-06");

    //var authorization = 'Bearer '+ sessionStorage.getItem("access_token");

    //const headers = new HttpHeaders({ 'Content-Type': 'application/json', "Authorization": authorization, responseType : 'blob'});

    //this.http.get(`${environment.mk3AzureApiUrl}` + '/Download/' + folderName + '/' + fileName + this.accountService.subscriptionKey)
    return this.http.get(
      `${environment.mk3AzureApiUrl}` +
        '/Download/' +
        folderName +
        '/' +
        fileName +
        this.accountService.subscriptionKey,
      { observe: 'response', responseType: 'blob' }
    );

    // let headers = new HttpHeaders().set('Accept', 'application/octet-stream');
    // //this.http.get(`${environment.mk3AzureApiUrl}` + '/Download/' + folderName + '/' + fileName + this.accountService.subscriptionKey)
    // this.http.get(`${environment.mk3AzureApiUrl}` + '/Download/' + folderName + '/' + fileName + this.accountService.subscriptionKey,
    //  { headers, observe: 'body', reportProgress: true, responseType: 'arraybuffer' })
    /*       .subscribe({
          next: (res: Blob) => {
            console.log('res: ' + res.toString);
            return resolve(res);
          },
          error: (error: any) => {
            reject(error);
            console.log('downloadFile error: ', error);
          },
          complete: () => {
          },
        });
    });
    return promise;*/
  }

  //Convert the result to a Case object and return
  private toCase(obj: any): Case {
    return {
      refNo: obj.refNo,
      caseNo: obj.caseNo,
      lastNameS: obj.lastNameS,
      policyNo: obj.policyNo,
      recD: obj.recD,
      status: obj.status,
      consultant: obj.consultant,
      situationStreet: obj.situationStreet,
      situationSuburb: obj.situationSuburb,
      state: obj.state,
      postCode: obj.postCode,
      inspDate: obj.inspDate,
      firstContact: obj.firstContact,
      lastContact: obj.lastContact,
      comments: obj.comments,
      bldCompletion: obj.bldCompletion,
      ctsCompletion: obj.ctsCompletion,
      endToEnd: obj.endToEnd,
      buildingCover: obj.buildingCover,
      contentsCover: obj.contentsCover,
      llPolicy: obj.llPolicy,
      emergencyRepair: obj.emergencyRepair,
      largeLoss: obj.largeLoss,
      holdContact: obj.holdContact,
      reportStatus: obj.reportStatus,
      tempAccom: obj.tempAccom,
      bldEstimate: obj.bldEstimate,
      ctsEstimate: obj.ctsEstimate,
      acceptance: obj.acceptance,
      cat: obj.cat1,
      peril: obj.peril,
      dol: obj.dol,
      sentReport: obj.sentReport,
      xsDateRecD: obj.xsDateRecD,
      ecdEstimatedCompletionDate: obj.ecdEstimatedCompletionDate,
      mk3Fee: obj.mk3Fee,
      bldAmtSettled: obj.bldAmtSettled,
      ctsAmtSettled: obj.ctsAmtSettled,
      reInspDate: obj.reInspDate,
      officeContact: obj.officeContact,
      claimStatus: obj.claimStatus,
      statusModified: obj.statusModified,
      reOpened: obj.reOpened,
      lastInspDate: obj.lastInspDate,
      contactDays: obj.contactDays,
      totalEstimate: obj.totalEstimate,
      emergencyRepairModified: obj.emergencyRepairModified,
      dateFinalised: obj.dateFinalised,
      sowProvided: obj.sowProvided,
      sowReceived: obj.sowReceived,
      buildingWorksCompleted: obj.buildingWorksCompleted,
      contentsWorksCompleted: obj.contentsWorksCompleted,
      assessmentType: obj.assessmentType,
      claimFinalisedDate: obj.claimFinalisedDate,
      originalStatus: obj.originalStatus,
      informedInsdAssessmentOutcome: obj.informedInsdAssessmentOutcome,
      signedContractReceived: obj.signedContractReceived,
      formerCaseNo: obj.formerCaseNo,
      optionalExtrasType: obj.optionalExtrasType,
      clientId: obj.clientId,
    };
  }

  private toYearlyStatistics(obj: any): YearlyStatistics {
    return {
      id: obj.id,
      sla: obj.sla,
      mk3Target: obj.mk3Target,
      // january: obj.january,
      // february: obj.february,
    };
  }

  private toEom(obj: any): EOM {
    return {
      id: obj.id,
      createDate: obj.createDate,
    };
  }

  private toVClaimReport(obj: any): VClaimReport {
    return {
      claimNumber: obj.claimNumber,
      otherReferenceNumber: obj.otherReferenceNumber,
      clientID: obj.clientID,
      propertyAddress: obj.propertyAddress,
      state: obj.state,
      postCode: obj.postCode,
      claimType: obj.claimType,
      quantumOfClaim: obj.quantumOfClaim,
      dateOfAppointment: obj.dateOfAppointment,
      dateOfInitialCustomerContact: obj.dateOfInitialCustomerContact,
      dateOfSiteVisit: obj.dateOfSiteVisit,
      dateOfFirstReport: obj.dateOfFirstReport,
      dateClosed: obj.dateClosed,
      statusWithPanels: obj.statusWithPanels,
      anticipatedCompletionDate: obj.anticipatedCompletionDate,
    };
  }

  private toStatistics(obj: any): Statistics {
    return {
      id: obj.id,
      clientId: obj.clientId,
      claimsVolume: obj.claimsVolume,
      initialContact: obj.initialContact,
      acknowledgement: obj.acknowledgement,
      firstVisit: obj.firstVisit,
      firstReport: obj.firstReport,
      averageDaysLastContact: obj.averageDaysLastContact,
      numberOfContacts: obj.numberOfContacts,
      claimCycle: obj.claimCycle,
      quantumOfOpenClaims: obj.quantumOfOpenClaims,
      finalisedCost: obj.finalisedCost,
      top3LossTypes: obj.top3LossTypes,
      createTime: obj.createTime,
    };
  }

  private toVClaim(obj: any): VClaim {
    return {
      refNo: obj.refNo,
      caseNo: obj.caseNo,
      recordedDate: obj.recordedDate,
      lastName: obj.lastName,
      situationStreet: obj.situationStreet,
      situationSuburb: obj.situationSuburb,
      state: obj.state,
      consultant: obj.consultant,
      consultantID: obj.consultantID,
      postCode: obj.postCode,
      dateOfLoss: obj.dateOfLoss,
      lastInspDate: obj.lastInspDate,
      lastAssessment: obj.lastAssessment,
      lastContact: obj.lastContact,
      contactDays: obj.contactDays,
      isHoldContact: obj.isHoldContact,
      acceptance: obj.acceptance,
      peril: obj.peril,
      estimatedCompletionDate: obj.estimatedCompletionDate,
      statusClaimTypeID: obj.statusClaimTypeID,
      statusClaimType: obj.statusClaimType,
      originalStatus: obj.originalStatus,
      reOpenedDate: obj.reOpenedDate,
      policyNo: obj.policyNo,
      oldCaseNo: obj.oldCaseNo,
      isVulnerableCustomer: obj.isVulnerableCustomer,
      iCACatastrophe: obj.iCACatastrophe,
      insurer: obj.insurer,
      optionalExtrasType: obj.optionalExtrasType,
      daysOpen: obj.daysOpen,
      wipLink: obj.wipLink,
    };
  }

  private toVClaimsAll(obj: any): VClaimsAll {
    return {
      refNo: obj.refNo,
      acceptance: obj.acceptance,
      assessmentType: obj.assessmentType,
      brandID: obj.brandID,
      brandName: obj.brandName,
      buildingAmountSettled: obj.buildingAmountSettled,
      buildingCompletionDate: obj.buildingCompletionDate,
      buildingCover: obj.buildingCover,
      buildingEstimate: obj.buildingEstimate,
      buildingSumInsured: obj.buildingSumInsured,
      buildingWorksCompleted: obj.buildingWorksCompleted,
      caseNo: obj.caseNo,
      catastrophe: obj.catastrophe,
      clientFriendlyName: obj.clientFriendlyName,
      clientID: obj.clientID,
      comments: obj.comments,
      consultant: obj.consultant,
      consultantID: obj.consultantID,
      contactDays: obj.contactDays,
      contentsAmountSettled: obj.contentsAmountSettled,
      contentsCompletionDate: obj.contentsCompletionDate,
      contentsCover: obj.contentsCover,
      contentsEstimate: obj.contentsEstimate,
      contentsSumInsured: obj.contentsSumInsured,
      contentsWorksCompleted: obj.contentsWorksCompleted,
      dateOfLoss: obj.dateOfLoss,
      dateScopeOfWorkProvided: obj.dateScopeOfWorkProvided,
      dateScopeOfWorkSignedReceived: obj.dateScopeOfWorkSignedReceived,
      daysOpen: obj.daysOpen,
      denialEstimate: obj.denialEstimate,
      emergencyRepairEst: obj.emergencyRepairEst,
      emergencyRepairModified: obj.emergencyRepairModified,
      emergencyRepairType: obj.emergencyRepairType,
      enDataClaimID: obj.enDataClaimID,
      endToEnd: obj.endToEnd,
      estimatedCompletionDate: obj.estimatedCompletionDate,
      excessBuilding: obj.excessBuilding,
      excessContents: obj.excessContents,
      excessDateReceived: obj.excessDateReceived,
      firstContactDate: obj.firstContactDate,
      formerCaseNo: obj.formerCaseNo,
      informedInsdAssessmentOutcome: obj.informedInsdAssessmentOutcome,
      inspectionDate: obj.inspectionDate,
      insuredFullName: obj.insuredFullName,
      insurerApprovalReceivedDate: obj.insurerApprovalReceivedDate,
      isENDataClaim: obj.isENDataClaim,
      isHoldContact: obj.isHoldContact,
      isLargeLoss: obj.isLargeLoss,
      isQuoteRequest: obj.isQuoteRequest,
      isRestorationClaim: obj.isRestorationClaim,
      isVulnerableCustomer: obj.isVulnerableCustomer,
      landLordPolicy: obj.landLordPolicy,
      lastContactDate: obj.lastContactDate,
      lastInspDate: obj.lastInspDate,
      lastName: obj.lastName,
      lorAmountSettled: obj.lorAmountSettled,
      officeContact: obj.officeContact,
      officeContactID: obj.officeContactID,
      optionalExtrasType: obj.optionalExtrasType,
      originalStatus: obj.originalStatus,
      peril: obj.peril,
      policyInception: obj.policyInception,
      policyInitialInception: obj.policyInitialInception,
      policyNumber: obj.policyNumber,
      postCode: obj.postCode,
      previousRefNo: obj.previousRefNo,
      receivedDate: obj.receivedDate,
      reInspectionDate: obj.reInspectionDate,
      reOpenedDate: obj.reOpenedDate,
      reportStatus: obj.reportStatus,
      restorationEstimate: obj.restorationEstimate,
      restorationSettledAmount: obj.restorationSettledAmount,
      sentReportDate: obj.sentReportDate,
      signedContractReceived: obj.signedContractReceived,
      situationStreet: obj.situationStreet,
      situationSuburb: obj.situationSuburb,
      state: obj.state,
      statusClaimTypeID: obj.statusClaimTypeID,
      statusModified: obj.statusModified,
      temporaryAccommodation: obj.temporaryAccommodation,
      tempAccomCost: obj.tempAccomCost,
      totalEstimate: obj.totalEstimate,
      mk3Fee: obj.mk3Fee,
      insurerContactName: obj.insurerContactName,
      statusClaimType: obj.statusClaimType,
      claimRecommendationID: obj.claimRecommendationID,
      claimRecommendation: obj.claimRecommendation,
      buildingPriceRange: obj.buildingPriceRange,
      buildingPriceRangeAverage: obj.buildingPriceRangeAverage,
    };
  }

  //Convert the result to a Case object and return
  private toCAT(obj: any): Cat {
    return {
      catId: obj.catId,
      icaCat: obj.icaCat,
    };
  }

  //Convert the result to a Case object and return
  private toNoteDetails(obj: any): ClaimNote {
    return {
      refNo: obj.refNo,
      date: obj.date,
      addedBy: obj.addedBy,
      initiatedBy: obj.initiatedBy,
      notes: obj.notes,
      private: obj.private,
      timeStamp: obj.timeStamp,
      followUpDate: obj.followUpDate,
      typeDescription: obj.typeDescription,
      subTypeDescription: obj.subTypeDescription,
      reasonDescription: obj.reasonDescription,
    };
  }

  //Convert the result to a Peril object and return
  private toPeril(obj: any): Peril {
    return {
      perilID: obj.perilID,
      peril: obj.peril,
      count: obj.count,
    };
  }

  //Convert the result to a Summary object and return
  private toSummary(obj: ReceivedSummary): ReceivedSummary {
    return {
      receivedCount: obj.receivedCount,
      contact24: obj.contact24,
      contact48: obj.contact48,
      inspectedCount: obj.inspectedCount,
      finalisedReceivedInPeriodCount: obj.finalisedReceivedInPeriodCount,
      finalisedCountAll: obj.finalisedCountAll,
      acceptedFinalised: obj.acceptedFinalised,
      partialFinalised: obj.partialFinalised,
      denialRecommendedFinalised: obj.denialRecommendedFinalised,
      cancelledCount: obj.cancelledCount,
      cycleTime: obj.cycleTime,
      cashSettled: obj.cashSettled,
      completedByMk3Partners: obj.completedByMk3Partners,
      withdrawnFinalised: obj.withdrawnFinalised,
      otherFinalised: obj.otherFinalised,
      totalOpen: obj.totalOpen,
      totalYetToBeInspected: obj.totalYetToBeInspected,
      total30DaysOrLess: obj.total30DaysOrLess,
      total31To60DaysOld: obj.total31To60DaysOld,
      total61To90DaysOld: obj.total61To90DaysOld,
      totalOver90DaysOld: obj.totalOver90DaysOld,
      totalReOpened: obj.totalReOpened,
    };
  }

  //Error handler
  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      //console.error('An error occurred:', error.error.message);
      this.alertService.error(error.error.message);
    } else {
      // console.error(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${error.error}`);
      this.alertService.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError('Error occured; please try again later.');
  }

  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 404: {
        return `Not Found: ${error.message}`;
      }
      case 403: {
        return `Access Denied: ${error.message}`;
      }
      case 500: {
        return `Internal Server Error: ${error.message}`;
      }
      default: {
        return `Unknown Server Error: ${error.message}`;
      }
    }
  }

  //GET case Contact by ID (refNo)
  getClaimContacts(id: number): Observable<any> {
    //}: Observable<Case[]>{
    return this.http
      .get(
        `${environment.claimContactApiUrl}` +
          '/' +
          id +
          this.accountService.subscriptionKey
      )
      .pipe(
        catchError((error) => {
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.clear();
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }
}
