<div class="centered-div" *ngIf="accountService.isUserLoggedIn() !== true">
  <table>
    <tr>
      <h2 style="color: var(--mk3red);">Mk3 Portal</h2>
    </tr>
    <tr><img src="_assets/mk3-logo.png" alt="logo" style="height:140px; width: 140px; float: center;"></tr>
    <tr>
      <h5><a href="javascript:void(0)" (click)="this.accountService.login()">Sign In</a></h5>
    </tr>
  </table>
</div>

<div class="headerlogo" *ngIf="accountService.isUserLoggedIn() === true">
  <img src="_assets/mk3-logo.png" alt="logo" style="height:140px; width: 140px; float: left;">
</div>

<nav *ngIf="accountService.isUserLoggedIn() === true">
  <ul style="padding-inline-start: 7px; padding-top: 0px; padding-right: 7px;">
    <li><a routerLink="/home" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: true}">Home</a></li>
    <li><a routerLink="" (click)="openSearchDialog(false)">Search Claim No</a></li>
    <li><a routerLink="" (click)="openSearchDialog(true)">Search Mk3 RefNo</a></li>
    <li><a routerLink="/cases" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: true}">Search Keyword</a></li>
    <!-- <li *ngIf="isMk3User"><a routerLink="/statistics" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: true}">Statistics Report</a></li> -->
    <li><a routerLink=""
        (click)="this.accountService.logout()">{{this.accountService.getName().substring(0, this.accountService.getName().indexOf(' '))}}&nbsp;Sign Out</a></li>

  </ul>
</nav>

<!-- main app container -->
<div class="app-container">
  <alert></alert>
  <router-outlet></router-outlet>
</div>
