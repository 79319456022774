import { Location } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, AlertService } from '@app/_services';
import { tap } from 'rxjs/operators';
import { RestService } from '@app/_services/rest.service';
import { FunctionsService } from '@app/_services/functions.service';
import { ClaimNote } from '@app/_models/noteDetails';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { AddNoteComponent } from './add-note/add-note.component';
import { VClaimsAll } from '@app/_models/vClaimsAll';
import { vClaimContact } from '@app/_models/claimContact';
import { publicDecrypt } from 'crypto';
import { GlobalValues } from '@app/_services/global-values';
import { Constants } from 'msal';
import { ITGroupMembers } from '@app/_helpers/constants';

@Component({
  selector: 'app-case-detail',
  templateUrl: './case-detail.component.html',
  styleUrls: ['./case-detail.component.scss'],
})
export class CaseDetailComponent implements OnInit {
  displayedNotesColumns: string[] = [
    'date',
    'initiatedBy',
    'addedBy',
    'notes',
    'followUpDate',
    'typeDescription',
    'subTypeDescription',
    'reasonDescription',
  ];
  dataSourceNotes!: MatTableDataSource<ClaimNote>;
  isLoading = true;
  vClaimsAll!: VClaimsAll;
  noteDetails!: ClaimNote;
  activeNoteRow = null;
  claimContact!: vClaimContact[];
  queryParam1:string |null = '';
  queryParam2:string |null='';
  isGroupIT =false;

  @ViewChild(MatSort, { static: true }) matSort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatTable)
  table!: MatTable<any>;

  constructor(
    private restService: RestService,
    private router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    public accountService: AccountService,
    private alertService: AlertService,
    public functionsService: FunctionsService,
    public dialog: MatDialog,
    public global: GlobalValues
  ) {
    this.isGroupIT = ITGroupMembers.includes(this.global.userEmailId!)
    this.fetchData(false);
  }

  async ngOnInit() {
    this.alertService.clear();
  }

  async fetchData(isAddNote: boolean) {
    await this.restService
      .getClaimByMk3Ref(this.route.snapshot.params['refNo'])
      .then(async (data) => {
        if (data && data.totalCount > 0) {
          data = this.functionsService.excludeClaims(data);
          this.vClaimsAll = { ...data.records[0] };
          this.isLoading = false;
          this.queryParam1 =String(this.vClaimsAll.refNo);
          this.queryParam2 = this.vClaimsAll.lastName;

          //Get Notes
          await this.restService
            .getClaimNotes(this.route.snapshot.params['refNo'])
            .subscribe(
              (notes) => {
                this.dataSourceNotes = new MatTableDataSource(this.functionsService.excludeTestClaims(notes,'refNo'));
                this.dataSourceNotes.sort = this.matSort;
              },
              (error) => {
                this.isLoading = false;
                this.alertService.error(error);
              }
            );

          //Get Claim Contacts
          await this.restService
            .getClaimContacts(this.route.snapshot.params['refNo'])
            .subscribe(
              (contacts) => {
                this.claimContact = this.functionsService.excludeTestClaims(contacts,'refNo');
              },
              (error) => {
                this.isLoading = false;
                this.alertService.error(error);
              }
            );
        } else {
          this.isLoading = false;
          this.router.navigate(['.']);
          this.alertService.error(
            'Invalid Claim. This claim either could be archived or not yet registered. Please contact Mk3 IT-Helpdesk'
          );
          return;
        }
      });
  }

  openAddNoteDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = '750px';

    dialogConfig.data = {
      refNo: this.vClaimsAll.refNo,
      caseNo: this.vClaimsAll.caseNo,
      lastNames: this.vClaimsAll.lastName,
    };
    this.dialog.open(AddNoteComponent, dialogConfig);
  }

  openNoteDeailsDialog(row: null, note: string): void {
    this.activeNoteRow = row;
    const dialogRef = this.dialog
      .open(NoteDetailsDialog, {
        minWidth: '400px',
        maxWidth: '1000px',
        data: { name: note },
      })
      .afterClosed()
      .pipe(tap(() => (this.activeNoteRow = null)));
    this.table.renderRows();
  }

  //Go back to the claims list page
  goBack() {
    this._location.back();
  }
}
export interface DialogData {
  name: string;
}
@Component({
  selector: 'note-details-dialog',
  templateUrl: 'note-details-dialog.html',
})
export class NoteDetailsDialog {
  constructor(
    public dialogRef: MatDialogRef<NoteDetailsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
